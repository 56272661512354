<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="mb-2">
        <!-- Date picker -->
        <b-col cols="12" md="7">
          <b-row>
            <b-col md="5  ">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="5 ">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2" class="d-flex align-items-end">
              <b-button @click="getActionList()" variant="primary ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- Search -->
        <!-- <b-col
					cols="12"
					md="5"
					class="mt-2 d-flex justify-content-end align-items-center"
				>
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getActionList()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
				</b-col> -->
      </b-row>
    </div>
    <div v-if="actionList && actionList.total > 0">
      <b-table
        :items="actionList.data"
        responsive
        :fields="fields"
        show-empty
        empty-text="No matching records found"
        class="text-center"
      >
        <template #cell(index)="data">
          <div class="ml-1">
            {{ actionList.from + data.index }}
          </div>
        </template>
        <template #cell(notification_status)="data">
          <div v-if="data.value === 'Pending'" class="pending ml-2">
            Pending
          </div>
          <div v-else></div>
        </template>

        <template #cell(notification_type)="data">
          <div v-if="data.value === 'medicine'" class="medicine ml-2">
            Medicine
          </div>
        </template>
      </b-table>
      <!-- source modal -->
      <b-modal id="add-source" title="Add Source" ok-only ok-title="Submit">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label="Enter Source Name">
            <div class="form-label-group">
              <b-form-input id="candlingName" placeholder="Enter Source Name" />
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-pagination
          :value="actionList.current_page"
          :total-rows="actionList.total"
          :per-page="actionList.per_page"
          align="right"
          @change="getActionList"
        >
        </b-pagination>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="
          require('@/assets/images/svg/infographics/no_data_infographic.svg')
        "
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No action found</h4>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BImg,
  BTab,
  BCardText,
  BFormDatepicker,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useActionList from "./useActionList";
import actionStoreModules from "../actionStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";

export default {
  components: {
    vSelect,
    vSelect,
  },
  data() {
    return {
      selected: null,
      selectedType: "candling",
      fields: [
        { key: "index", label: "s.no" },
        { key: "name", label: "name" },
        { key: `from_day`, label: "from to" },
        { key: `to_day`, label: "to day" },
        { key: `notification_status`, label: "status" },
        { key: `notification_type`, label: "type" },
        {
          key: "created_at",
          label: "date",
          formatter: (value) => {
            return moment(value).format("MMMM Do YYYY");
          },
        },
      ],
      search_filter: {
        input_field: "",
        to_date: "",
        from_date: "",
      },
      actionList: {},
    };
  },
  computed: {
    rows() {
      return this.actionList.length;
    },
  },
  methods: {
    moment() {
      return moment();
    },
    onTypeChange(e) {
      this.getActionList();
    },
    getActionList(pageNo = 1) {
      const farmId = this.$route.params.farmId;
      this.selectedType = "medicine";

      let url = `web/farm/${farmId}/notification/${this.selectedType}`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }

      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.actionList = response.data.notificationList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getActionList();
    },
  },
  created() {
    this.farmId = this.$route.params.farmId;
    if (this.farmId) {
      this.getActionList();
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, actionStoreModules);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      "Downloaded",
      "Draft",
      "Paid",
      "Partial Payment",
      "Past Due",
    ];

    const {
      fetchInvoices,
      AlarmTable,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useActionList();

    return {
      fetchInvoices,
      AlarmTable,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.header-title {
  height: 60px;
  border-bottom: 1px solid #ebe9f1;
  font-weight: 500;
  color: #636363;
  font-size: 1.714rem;
  margin-bottom: 1rem;
}
.pending {
  background-color: #7367f0;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  border-radius: 10px;
  padding: 10px 10px;
}
.medicine {
  background-color: #2196f3;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  border-radius: 10px;
  padding: 10px 10px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
