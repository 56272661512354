<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Action</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- tabs -->

		<!-- Table Container Card -->
		<b-card no-body class="mb-2">
			<b-tabs class="m-2" lazy>
				<b-tab active>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/feed-supplement.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Feeding</span>
						</div>
					</template>
					<Feeding-Action-List />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/vaccine.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Vaccination</span>
						</div>
					</template>

					<Vaccination-Action-List />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/medicine.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Medicine</span>
						</div>
					</template>
					<Medicine-Action-List />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>

import vSelect from "vue-select";
import FeedingActionList from "./FeedingActionList.vue";
import MedicineActionList from "./MedicineActionList.vue";
// import TransferActionList from "./TransferActionList.vue";
import VaccinationActionList from "./VaccinationActionList.vue";
export default {
	components: {
		vSelect,
		FeedingActionList,
		MedicineActionList,
		// TransferActionList,
		VaccinationActionList,
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
